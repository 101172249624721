<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>企业管理</a-breadcrumb-item>
            <a-breadcrumb-item>更新日志</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
        <div class="clearfix table-tools">
            <div class="buttons">
            <a-form layout='inline'>
                <a-form-item>
                <a-button icon="plus" type="primary" @click="showEditModal()">提交更新</a-button>
                </a-form-item>
            </a-form>
            </div>
            <div class="search">
                <a-form layout='inline' @submit="searchList">
                <a-form-item>
                <a-tooltip placement="topLeft" >
                    <template slot="title">
                    <span>更新版本</span>
                    </template>
                    <a-input v-model.trim="searchParams.search.upgrade_version" allowClear placeholder="请输入更新版本名称" style="width: 160px"/>
                </a-tooltip>
                </a-form-item>

                <a-form-item>
                    <a-range-picker
                    :ranges="rangesData"
                    v-model='searchDatas.promise'
                    allowClear
                    style="width: 240px"
                    @change="(val,time)=>handleTime(val,time,'promise')" />
                </a-form-item>


                <a-form-item>
                <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
                </a-form-item>
            </a-form>
            </div>
        </div>
        <div class="table">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-table size="small" :pagination="false" :bordered='false' rowKey="id"
            :columns="columns" :dataSource="list" :scroll="{ x: 1300 }">
            <template slot="index" slot-scope="text, record , index">
                <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
            </template>
            <template slot="action" slot-scope="text,record">
            <div>
                <a @click="showEditModal(record)" href="javascript:;">编辑</a>
            </div>
            </template>
            </a-table>
        </div>
        <div class="page">
            <a-pagination
            :pageSizeOptions="pageSizeOptions"
            :total="pageParams.totalCount"
            showSizeChanger
            :pageSize="pageParams.perPage"
            v-model="current"
            @change="changePage"
            @showSizeChange="onShowSizeChange"
            :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
            >
                <template slot='buildOptionText' slot-scope='props'>
                <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                <span v-if="props.value==='100'">100条/页</span>
                </template>
            </a-pagination>
        </div>
        </div>
    </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '更新版本', dataIndex: 'upgrade_version', key: 'upgrade_version',width:'120px'},
  { title: '更新日期', dataIndex: 'upgrade_date', key: 'upgrade_date',width:'120px'},
  { title: '更新内容', dataIndex: 'upgrade_content', key: 'upgrade_content',width:'240px'},
  { title: '操作账户', dataIndex: 'created_by', key: 'created_by',width:'120px'},
  { title: '创建时间', dataIndex: 'created_at', key: 'created_at',width:'120px'},
  { title: '操作', key: 'operation', width: 130, scopedSlots: { customRender: 'action' },align:"right"}
]

    import moment from 'moment'
    import ranges from "@/common/mixins/ranges"
    import tableMixins from '@/common/mixins/table'
    export default {
        name:'upgradeLog',
        data() {
            return {
                columns,
                loading: false,
                searchDatas:{},
            }
        },
        mixins:[tableMixins,ranges],
        methods: {
            async getList() {
                this.loading = true
                await this.$store.dispatch('enterpriseUpgradeLogAction', { data: this.searchParams })
                .then(res=>{
                    this.list = res.items
                    this.loading = false
                    this.pageParams = res._meta
                })
            },
            handleStatusButChange(e){
                if(this.searchParams.search.smartservice_status == e){
                    this.searchParams.search.smartservice_status = undefined
                }else{
                    this.searchParams.search.smartservice_status = e
                }
                this.getList()
            },
            handleTypeButChange(e){
                if(this.searchParams.search.smartservice_level == e){
                    this.searchParams.search.smartservice_level = undefined
                }else{
                    this.searchParams.search.smartservice_level = e
                }
                this.getList()
            },
            showEditModal(item){
                if(item){
                    this.$router.push({path:'/workservice/upgradeLog/update',query:{id:item.id}})
                }else{
                    this.$router.push({path:'/workservice/upgradeLog/add'})
                }
            },
            handleTime(val,time,name){
                this.searchDatas[name] = val
                this.$forceUpdate()
                this.searchParams.search.start_date = time[0]
                this.searchParams.search.end_date = time[1]
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>